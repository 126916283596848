.auth-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  background: url("/fun_background.png") no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 72px;

    .logo {
      width: 153px;
      height: 40px;
      background: url(../../assets/logo_white.png) no-repeat;
    }
    .about {
      font-weight: 500;
      color: #fff;
    }
  }

  .body {
    margin-bottom: 72px;
    flex-direction: column;
    flex-grow: 1;
    .title {
      font-size: 52px;
      font-family: Poppins-ExtraBold, Poppins;
      font-weight: 800;
      color: rgba(255, 255, 255, 1);
      line-height: 73px;
      margin-top: 32px;
      text-align: center;
    }
    .sub-title {
      text-align: center;
      font-size: 36px;
      font-family: Times-BoldItalic, Times;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
      line-height: 43px;
    }
    .items {
      margin-bottom: 40px;
      .item {
        height: 33px;
        margin-top: 20px;
        font-size: 24px;
        font-family: Poppins-Medium, Poppins;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 35px;
        background-position: left center;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        padding-left: 36px;
      }
      .item1 {
        background-image: url(../../assets/fun_max.png);
      }
      .item2 {
        background-image: url(../../assets/fun_toy.png);
      }
      .item3 {
        background-image: url(../../assets/fun_protect.png);
      }
    }
    .btns {
      display: flex;
      align-items: center;
    }
    .btn {
      width: 200px;
      height: 60px;
      border-radius: 30px;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
    }
    .btn1 {
      border: 2px solid rgba(255, 255, 255, 1);
      margin-right: 40px;
      color: #fff;
    }
    .btn2 {
      background: rgba(255, 255, 255, 1);
      color: rgba(0, 0, 0, 1);
    }
  }
}

@media only screen and (max-width: 768px) {
  .mainPage {
    .header {
      height: 48px;
      padding: 0 15px;

      .logo {
        width: 123px;
        height: 32px;
        background: url(../../assets/logo_white.png) no-repeat;
        background-size: cover;
      }
    }
    .body {
      .title {
        font-size: 32px;
        line-height: 48px;
        margin-top: 18px;
      }
      .sub-title {
        font-size: 24px;
        line-height: 39px;
      }
      .items {
        margin-top: 32px;
        .item {
          font-size: 16px;
          line-height: 33px;
          margin-top: 4px;
        }
      }
      .btns {
        .btn {
          height: 40px;
          width: 120px;
          font-size: 16px;
        }
      }
    }
  }
}
