.home {
  display: flex;
  flex-direction: column;
  // height: 100vh;

  .donateBtn {
    cursor: pointer;
    width: 200px;
    height: 48px;

    background: #ff7838;
    border-radius: 24px;

    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);

    margin: 88px auto 0 auto;

    .donateIcon {
      width: 25px;
      height: 24px;
      background: url(../../assets/fun_donate.png) no-repeat;
      margin-right: 7px;
    }
  }

  .goals {
    flex-shrink: 0;
    width: 178px;
    // margin-left: 48px;
    // margin-right: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      font-weight: 500;
      line-height: 21px;
    }
  }
}
