@media only screen and (max-width: 768px) {
  .ant-dropdown {
    max-width: 92vw;
    .ant-dropdown-menu-item {
      white-space: break-spaces;
    }
  }
  .home {
    .donateBtn {
      margin-top: 30px;
    }

    .DonateViewList {
      .DonateBox {
        min-height: auto;
        margin-bottom: 10px;
        flex-direction: row;
        .body {
          min-width: 100px;
          flex-shrink: 0;
          padding-left: 20px;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
    .your-donation-btn {
      margin-top: 10px;
    }
  }

  .no-donate-wrap {
    justify-content: flex-start;
  }

  .ant-picker-date-panel {
    width: 50vw;
    .ant-picker-content {
      width: 46vw;
      th {
        width: auto;
      }
    }
  }
}
