$primary-color: #0cbeff;

@function rpx($px) {
  @return $px/750.0 * 100vw;
}

.ant-steps-item-icon {
  background-color: #eaebec !important;
  border-color: #eaebec !important;
  span {
    color: #fff !important;
  }
}
.ant-steps-item-title {
  font-size: 12px !important;
}
.ant-steps-item-content {
  // width: 170px !important;
}
.ant-steps-item-active,
.ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.ant-card {
  border-radius: 8px;
}

.ant-modal-confirm-body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-modal-confirm-title {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
  }

  .ant-modal-confirm-body {
    .action {
      margin-right: 0;
    }
    .anticon-check-circle,
    .anticon-close-circle {
      display: block;
      font-size: 96px;
      float: none;
      margin-right: 0;
    }
    .ant-modal-confirm-content {
      font-size: 18px;
      text-align: center;
    }

    > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
      margin-left: 0px;
    }
  }

  .ant-modal-confirm-btns {
    float: none;
    text-align: center;
  }
}

.fm-btn {
}
