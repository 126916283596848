.FundraiserDlg {
  .ant-steps {
    margin-bottom: 30px;
  }
  .ant-steps-item-title {
    line-height: 16px;
    font-size: 14px;
  }
  .steps-action {
    text-align: center;
  }

  .finish-panel {
    padding: 0 40px;
  }
}

.leave-page {
  .ant-modal-confirm-title {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 20px;
  }
}
