$primary-color: #0cbeff;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 14px;
}

.container {
  // padding-top: 24px;
  width: 100%;
  max-width: 1196px;
  margin: 0 auto;
}

.fcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: #828587;
}
.fr {
  .ant-modal-title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: rgba(25, 26, 29, 1);
  }

  .ant-modal-header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: none;
  }

  .ant-modal-content {
    border-radius: 12px;
  }
}

.btnEdit {
  width: 20px;
  height: 20px;
  background: url(../assets/fun_edit.png) no-repeat;
  background-size: cover;
  cursor: pointer;
  display: inline-block;
}

.btnDel {
  width: 20px;
  height: 20px;
  background: url(../assets/fun_close_grey.png) no-repeat;
  background-size: cover;
  cursor: pointer;
  display: inline-block;
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: "";
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: "";
}

.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  padding-bottom: 6px;
  content: "*";
}

.ant-layout {
  background: none !important;
}

.ant-btn-primary,
.ant-btn-primary:hover {
  background: $primary-color;
  border-color: $primary-color;
}

.donate-bg {
  background-image: url("/donate_web_bg.png");
}

.ant-input-number-input-wrap input {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .donate-bg {
    background-image: url("/donate_mobile_bg.png");
  }
}
