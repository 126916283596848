.FrCard {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  min-height: 140px;
  margin-top: 20px;
  padding-bottom: 16px;
  .FrCardHeader {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 16px;
    position: relative;
    .title {
      font-size: 16px;
      padding-right: 30px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(25, 26, 29, 1);
  }

  .tip {
    font-size: 16px;
    color: #828587;
    margin-left: 8px;
  }

  .addBtn {
    width: 92px;
    height: 32px;
    background: rgba(12, 190, 255, 1);
    border-radius: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    right: 16px;
    position: absolute;
    cursor: pointer;
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      background: url(../assets/fun_add_white.png) no-repeat;
    }
  }

  .body {
    text-align: justify;
  }

  .FrCardSelectHeader {
    background-color: #f8f8f8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    .select {
      color: #ff7838;
      font-size: 24px;
      position: absolute;
      right: 16px;
    }
  }
}
