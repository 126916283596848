.LoginPage {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;

  .LoginLeft {
    flex-grow: 1;
    height: 100%;
    position: relative;
    font-weight: 600;
    font-size: 28px;
    color: #fff;
    .wrap {
      height: 440px;
      flex-direction: column;
      width: 100%;
      display: flex;
    }
    .title-wrap {
      flex-direction: column;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      .title {
        flex-grow: 0;
      }
    }
    .title {
      font-size: 52px;
      font-family: Times-BoldItalic, Times;
      font-weight: 800;
      color: rgba(255, 255, 255, 1);
      line-height: 73px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
    .sub-title {
      font-size: 28px;
      text-align: center;
    }
    .tip {
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      padding-right: 60px;
      text-align: right;
    }
  }

  .LoginRight {
    width: 568px;
    height: 100%;
    background-color: #ffffff;
    color: #000;

    .ant-form {
      width: 488px;

      .invite-header {
        font-size: 20px;
        font-weight: 600;
        color: #010101;
        line-height: 30px;
        background: url(../../assets/icon_invitation_blue.png) no-repeat;
        background-position: left center;
        background-size: 24px 24px;
        padding-left: 32px;
      }
      .invite-email {
        margin-top: 16px;
        margin-bottom: 60px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 21px;
      }

      .ant-form-item-label {
        padding-bottom: 4px;
        label {
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          line-height: 18px;
          height: 17px;
        }
      }
      .btn {
        // width: 488px;
        height: 40px;
        background: rgba(12, 190, 255, 1);
        border-color: rgba(12, 190, 255, 1);
        border-radius: 2px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
      }

      .help {
        font-size: 12px;
        font-weight: 400;
        color: #909090;
        line-height: 18px;
      }
      .tip {
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #000000;
        line-height: 18px;
        .tip2 {
          cursor: pointer;
          padding-left: 8px;
          color: #0cbeff;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .LoginPage {
    .LoginLeft {
      display: none;
    }
    .LoginRight {
      width: 100%;
      // background-color: rgba(255,255,255,0.7);

      padding: 0 15px;
      .ant-form {
        width: 100%;
      }
    }
  }
}
