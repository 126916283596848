.select-list {
  .select-list-item {
    border-radius: 2px;
    border: 1px solid #e2e2e2;
    padding: 0px 0px 0px 0px;
    color: #828587;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    margin-bottom: 12px;
    cursor: pointer;
    position: relative;

    .ant-typography {
      color: #828587;
      flex-grow: 1;
      margin-bottom: 0;
    }
    textarea {
      color: #828587;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 36px;
    }
    .anticon-check {
      position: absolute;
      top: 10px;
      right: 8px;
    }
    span {
      font-size: 20px;
      margin-left: 20px;
      flex-shrink: 0;
    }

    &.active {
      border: 3px solid #0cbeff;
      textarea {
        color: #191a1d;
      }
      span {
        color: #0cbeff;
      }
      .ant-typography {
        color: #191a1d;
      }
    }
  }
}
