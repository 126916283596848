@import "../../common/fm.scss";

.class-page {
  max-width: 720px;
  margin: 0 auto;

  .side {
    .ant-btn {
      margin-bottom: 20px;
      font-size: 16px;
      white-space: normal;
      min-height: 40px;
      height: auto;
    }
  }
  .ant-list-header {
    padding: 0 16px !important;

    .headerBar {
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
  .ant-list-items {
    height: 432px;
    overflow: auto;
    .ant-list-item:nth-child(even) {
      background-color: #f8f8f8;
    }
  }
}

.class-dlg {
  .ant-modal-footer {
    text-align: center;
    font-weight: bold;
  }
}

.event-card {
  .card-title {
    text-align: center;
    height: 64px;
    line-height: 64px;
    font-size: 24px;
    font-weight: bold;
  }
  .card-bar {
    height: 52px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 120px;
    .ant-descriptions-item {
      padding-bottom: 0;
      .ant-descriptions-item-label {
        color: #999;
      }
      .ant-descriptions-item-content {
        color: #333;
      }
    }
  }
  .event-body {
    padding: 15px 40px;
    height: 450px;
    .students {
      overflow: auto;
      height: 360px;
    }
  }
}
