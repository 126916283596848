.teacher-index {
  .card1 {
    margin-top: 40px;
    background: url(../../assets/teacher_index/bg_home_yellow.png) no-repeat;
    background-size: cover;
    position: relative;

    .card11 {
      background: url(../../assets/teacher_index/pic_bg_yellow.png) no-repeat;
      position: absolute;
      left: -42px;
      top: 100px;
    }
    .card12 {
      background: url(../../assets/teacher_index/pic_progress.png) no-repeat;
      position: absolute;
      right: -22px;
      top: 294px;
    }
    .card13 {
      background: url(../../assets/teacher_index/pic_phone.png) no-repeat;
      position: absolute;
      left: 659px;
      top: 490px;
      z-index: 99;
    }
  }

  .card2 {
    margin-top: 40px;
    background-size: cover;
    background: url(../../assets/teacher_index/big_blue_home.png) no-repeat;
    position: relative;

    .card21 {
      background: url(../../assets/teacher_index/pic_parents.png) no-repeat;
      position: absolute;
      left: -42px;
      top: 280px;
    }
    .card22 {
      background: url(../../assets/teacher_index/pic_tool.png) no-repeat;
      position: absolute;
      right: -42px;
      top: 160px;
    }
  }
  padding-bottom: 40px;

  .btn {
    width: 140px;
    height: 48px;
    background: #825fff;
    border-radius: 24px;

    font-size: 20px;
    font-family: BalsamiqSans-Bold, BalsamiqSans;
    font-weight: bold;
    color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  .btn-new-class {
    position: absolute;
    top: 206px;
    left: 689px;
  }

  .btn-new-event {
    position: absolute;
    top: 470px;
    left: 362px;
  }

  .btn-new-fundraising {
    position: absolute;
    top: 206px;
    left: 362px;
  }
}

.teacher-index-mobile {
  padding: 5.333333vw;

  .img-card {
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 4vw;
  }

  .start {
    background: url(../../assets/mobile/teacher_index/button_start.png) no-repeat;
    background-size: contain;
    position: absolute;
  }

  .card1 {
    background: url(../../assets/mobile/teacher_index/pic_event.png) no-repeat;
    background-size: contain;
  }

  .card2 {
    background: url(../../assets/mobile/teacher_index/background_creat.png) no-repeat;
    background-size: contain;
    position: relative;
    margin-bottom: 12.2vw;

    .card21 {
      background-image: url(../../assets/mobile/teacher_index/pic_challenge.png);
      position: absolute;
    }
    .card22 {
      background-image: url(../../assets/mobile/teacher_index/pic_monitor.png);
      position: absolute;
    }
    .card23 {
      background-image: url(../../assets/mobile/teacher_index/pic_phone.png);
      position: absolute;
    }
  }

  .card3 {
    background: url(../../assets/mobile/teacher_index/background_friend.png) no-repeat;
    background-size: contain;
    position: relative;

    .card31 {
      background-image: url(../../assets/mobile/teacher_index/pic_parents.png);
      position: absolute;
    }
    .card32 {
      background-image: url(../../assets/mobile/teacher_index/pic_families.png);
      position: absolute;
    }
  }

  .card4 {
    background: url(../../assets/mobile/teacher_index/pic_tools.png) no-repeat;
    background-size: contain;
  }
}
