@import "../../../common/fm.scss";

.students-box {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  padding: 20px 20px 10px 20px;
  background-color: #ffffff;
  height: 400px;
  overflow-y: auto;
  .grade-box {
    .grade-title {
      font-size: 16px;
      font-weight: 600;
      color: #191a1d;
      line-height: 25px;
      margin-top: 20px;
    }
    .ant-checkbox-wrapper {
      margin-top: 12px;
    }
  }
}
