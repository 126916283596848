.donate-page {
}
.donate-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    width: 312px;
    height: 80px;
    background: url(../../assets/logo_white.png) no-repeat;
    margin-top: 60px;
    margin-bottom: 40px;
    background-size: cover;
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    color: #ffffff;
    line-height: 60px;
    text-align: center;
  }
  .invitation {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    padding: 14px 32px;

    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    line-height: 21px;

    margin-top: 24px;
    margin-bottom: 40px;
  }

  .ant-card {
    width: 600px;
    padding: 32px 64px;

    &.error {
      padding: 32px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .donate-page {
    padding: 20px 20px;
    .title {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      line-height: 36px;
    }
    .invitation {
      padding: 7px 16px;
      margin-top: 12px;
      margin-bottom: 20px;
    }
    .donate-form {
      .logo {
        width: 194px;
        height: 50px;
        margin-top: 0;
        margin-bottom: 16px;
        background-size: cover;
      }

      .ant-input-number {
        width: 150px;
        input {
          line-height: 44px;
        }
      }

      .ant-card {
        width: 100%;
        padding: 16px 12px;
        .ant-card-body {
          padding: 0;
        }
        .ant-result {
          padding: 24px 16px;
        }
      }
    }
  }
}
