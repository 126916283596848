.App {
  // display: flex;
  // flex-direction: column;
  min-height: 100vh;
  // background: none;

  background: center / cover url(/fun_background.png) no-repeat fixed !important;

  .headerWrap {
    background-color: #ffffff;
    height: auto;
    padding: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;

    height: 72px;

    .logo {
      width: 153px;
      height: 40px;
      background: url(../assets/logo_blue.png) no-repeat;
      flex-shrink: 0;
      background-size: contain;
    }

    .menus {
      display: flex;
      align-items: center;
      height: 72px;
      border-bottom: none;
      flex-grow: 1;
      justify-content: flex-end;
      .menu {
        margin-left: 40px;
        cursor: pointer;
        color: #828587;
        height: 72px;
      }
      .ant-menu-item-selected,
      .ant-menu-submenu-selected {
        font-weight: bold;
        color: #191a1d;
      }
    }
    .mobile-menus {
      display: none;
    }
    .user-name {
      margin-left: 30px;
      background: url(../assets/icon_account.png) no-repeat;
      background-position: left center;
      background-size: 24px 24px;
      padding-left: 30px;
      padding-right: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #191a1d;
    }
  }

  .appBody {
    margin-top: 72px;
  }

  // .app-bg {
  //   background: url("/fun_background.png") no-repeat !important;
  //   background-size: cover;
  //   background-position: center center;
  //   background-attachment: fixed;
  // }
}

.fcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .App {
    .headerWrap {
      padding-left: 15px;
    }
    .header {
      height: 48px;
      line-height: 24px;
      padding: 0 15px;
      .logo {
        width: 118px;
        height: 32px;
        background-size: cover;
        flex-grow: 0;
      }
      .menus {
        display: none;
      }
      .mobile-menus {
        display: block;
      }
    }

    .appBody {
      margin-top: 48px;
    }
    .body-wrapper {
      padding: 10px 15px;
    }
  }
}
