.DonateBox {
  min-height: 168px;
  border-radius: 8px;
  border: 1px solid rgba(238, 238, 238, 1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .info {
    font-weight: 500;
    color: rgba(130, 133, 135, 1);
    line-height: 21px;
  }
  .btn {
    width: 92px;
    height: 32px;

    background: #ff7838;
    border-radius: 20px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 26px;
  }
  .input-btn {
    background-color: #ffffff;
    color: #ff7838;
    border: solid 1px #ff7838;;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
      margin-top: 16px;
    }
  }
  &.active {
    border: solid 1px #0cbeff;
  }
}

.DonateAmount {
  color: #191a1d;
  font-size: 16px;
  font-weight: 600;

  display: flex;
  line-height: 16px;
  align-items: flex-end;
}

.DonateViewList,
.DonateEditList {
  display: flex;
  align-items: center;
  justify-content: center;

  .info {
    text-align: left;
  }

  .DonateAmount {
    justify-content: left;
    // margin-bottom: 10px;
  }
}
.DonateViewList {
  .body {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .DonateBox {
    .info {
      font-weight: 400;
    }
  }
}
.DonateEditList {
  height: 90%;
  .DonateBox {
    align-items: center;
    padding: 12px;
    min-height: 176px;
    .body {
      width: 100%;
    }
    .info {
      text-align: center;
    }
    .DonateAmount {
      justify-content: center;
      margin-bottom: 10px;
    }
  }
}
