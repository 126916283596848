.DonateDlg {
  .tip {
    color: #828587;
    margin-top: 12px;
  }

  .card-list {
    .card-item {
      height: 92px;
      border-radius: 12px;
      border: 1px solid #eeeeee;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: url(../../../../assets/bankcard_black.png) no-repeat;
      background-size: 72px 52px;
      background-position: 20px center;
      padding: 20px 20px 20px 124px;
      &.selected {
        border: 1px solid #0cbeff;
        background-image: url(../../../../assets/bankcard_default.png);
      }
    }
  }
  .btn {
    width: 92px;
    height: 32px;

    background: #ff7838;
    border-radius: 20px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 26px;
  }

  .donate-input {
    color: #191a1d;
    width: 150px;
    font-size: 28px;
    font-weight: 600;

    input {
      text-align: center;
    }
  }

  .ant-input-number {
    width: 150px;
    input {
      line-height: 44px;
    }
  }
}
