.RegPage {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: #000;

  .LoginLeft {
    flex-grow: 1;
    height: 100%;
    position: relative;
    font-weight: 600;
    font-size: 28px;
    color: #fff;
    .wrap {
      height: 440px;
      flex-direction: column;
      width: 100%;
      display: flex;
    }
    .title-wrap {
      flex-direction: column;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      .title {
        flex-grow: 0;
      }
    }
    .title {
      font-size: 52px;
      font-family: Times-BoldItalic, Times;
      font-weight: 800;
      color: rgba(255, 255, 255, 1);
      line-height: 73px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }
    .sub-title {
      font-size: 28px;
      text-align: center;
    }
    .tip {
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      padding-right: 60px;
      text-align: right;
    }
  }

  .LoginRight {
    width: 568px;
    height: 100%;
    overflow: auto;
    background-color: #ffffff;
    padding: 24px 40px;
    .ant-typography {
      color: #000;
    }
    .ant-form-item-label {
      padding-bottom: 4px;
      > label {
        font-size: 12px;
        height: auto;
        color: #000;
        font-weight: 500;
      }
    }
    .ant-form-item {
      margin-bottom: 12px;
    }

    .tip {
      color: #0cbeff;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .RegPage {
    .LoginLeft {
      display: none;
    }
    .LoginRight {
      width: 100%;
      // background-color: rgba(255,255,255,0.7);

      padding: 0 15px;
      .ant-form {
        width: 100%;
      }
    }
  }
}
